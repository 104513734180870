import { API, Auth } from "aws-amplify";
import uuidv4 from "uuid";
import { initBoard } from "../reducers/board-reducer";

export const handleSignOut = () => {
  Auth.signOut()
    .then(() => {
      window.location = "/";
    })
    .catch(err => console.log(err));
};

export const fetchUserData = (user, updateMethod) => {
  let jwtToken = user.signInUserSession.idToken.jwtToken;
  const options = {
    headers: {
      Authorization: jwtToken
    }
  };

  API.get("userAPI", "/" + user.signInUserSession.idToken.payload.sub, options)
    .then(response => {
      var options = {
        headers: {
          Authorization: jwtToken
        },
        body: {
          stripeId: user.attributes["custom:stripeId"],
          userId: user.username
        }
      };

      API.put("paymentAPI", "/request", options)
        .then(r => {
          var data = {
            user: {
              name: user.signInUserSession.idToken.payload.preferred_username,
              uuid: user.username,
              email: user.signInUserSession.idToken.payload.email,
              cases: response.cases
            },
            stripe: r.customer
          };
          return updateMethod(data);
        })
        .catch(err => console.log(err));
    })
    .catch(err => {
      console.log(err);

      var options = {
        headers: {
          Authorization: jwtToken
        },
        body: {
          stripeId: user.attributes["custom:stripeId"],
          userId: user.username
        }
      };

      API.put("paymentAPI", "/request", options)
        .then(r => {
          var data = {
            user: {
              name: user.signInUserSession.idToken.payload.preferred_username,
              uuid: user.username,
              email: user.signInUserSession.idToken.payload.email,
              cases: []
            },
            stripe: r.customer
          };
          return updateMethod(data);
        })
        .catch(err => console.log(err));
    });
};

export const changeCaseClosed = (
  user,
  updatedCase,
  newValue,
  updateMethod,
  cases,
  stripe
) => {
  var thisDate = new Date();
  let jwtToken = user.signInUserSession.idToken.jwtToken;
  let userId = user.signInUserSession.idToken.payload.sub;
  let dateMilli = thisDate.getTime();
  var userData;

  if (cases !== undefined) {
    cases = cases.map(c => {
      if (c.caseId === updatedCase.caseId) {
        c = {
          ...c,
          open: newValue,
          date: dateMilli
        };
      }
      return c;
    });
    userData = {
      body: {
        userId: userId,
        // this will send update the case to dynamo
        cases: [...cases]
      },
      headers: {
        Authorization: jwtToken
      }
    };
  }
  // puts record back into dynamo
  API.put("userAPI", "", userData)
    .then(() => {
      var data = {
        user: {
          name: user.signInUserSession.idToken.payload.preferred_username,
          // Keep this in mind to manually add a case and save the API from having to put and then get to refresh the case list
          cases: userData.body.cases
        },
        stripe: stripe
      };
      updateMethod(data);
    })
    .catch(err => {
      console.log(err);
    });
};

export const addCase = (
  user,
  newCaseName,
  newCaseNum,
  newPetitioner,
  newRespondant,
  newCaseRep,
  updateMethod,
  cases,
  newState,
  newCounty,
  stripe
) => {
  var thisDate = new Date();
  let jwtToken = user.signInUserSession.idToken.jwtToken;
  let userId = user.signInUserSession.idToken.payload.sub;
  let newCaseId = uuidv4();
  let dateMilli = thisDate.getTime();

  const init = {
    ...initBoard
  };

  const boardData = {
    body: {
      lanes: init.lanes,
      finances: init.finances
    },
    headers: {
      Authorization: jwtToken
    }
  };
  var userData;

  if (cases !== undefined) {
    userData = {
      body: {
        userId: user.signInUserSession.idToken.payload.sub,
        // this will send the new case to dynamo
        cases: [
          ...cases,
          {
            caseId: newCaseId,
            caseName: newCaseName,
            caseNum: newCaseNum,
            petitioner: newPetitioner,
            respondant: newRespondant,
            representing: newCaseRep,
            open: true,
            date: dateMilli,
            state: newState,
            county: newCounty
          }
        ]
      },
      headers: {
        Authorization: jwtToken
      }
    };
  } else {
    userData = {
      body: {
        userId: user.signInUserSession.idToken.payload.sub,
        // this will send the new case to dynamo
        cases: [
          {
            caseId: newCaseId,
            caseName: newCaseName,
            caseNum: newCaseNum,
            petitioner: newPetitioner,
            respondant: newRespondant,
            representing: newCaseRep,
            open: true,
            date: dateMilli,
            state: newState,
            county: newCounty
          }
        ]
      },
      headers: {
        Authorization: jwtToken
      }
    };
  }

  // puts record back into dynamo
  API.put("userAPI", "", userData)
    .then(() => {
      var data = {
        user: {
          name: user.signInUserSession.idToken.payload.preferred_username,
          // Keep this in mind to manually add a case and save the API from having to put and then get to refresh the case list
          cases: userData.body.cases
        }
      };
      data.stripe = stripe;
      updateMethod(data);

      // Creates s3 object
      API.put("caseAPI", "/" + userId + newCaseId, boardData).catch(err => {
        console.log(err);
      });
    })
    .catch(err => {
      console.log(err);
    });
};

//edits case info, puts back into dynamo
export const editCase = (
  caseId,
  user,
  newCaseName,
  newCaseNum,
  newPetitioner,
  newRespondant,
  newCaseRep,
  updateMethod,
  cases,
  newCounty
) => {
  let jwtToken = user.signInUserSession.idToken.jwtToken;

  var newCases = cases.map(c => {
    if (c.caseId === caseId) {
      c.caseName = newCaseName;
      c.caseNum = newCaseNum;
      c.petitioner = newPetitioner;
      c.respondant = newRespondant;
      c.representing = newCaseRep;
      c.county = newCounty;
    }

    return c;
  });

  let userData = {
    body: {
      userId: user.signInUserSession.idToken.payload.sub,
      // this will send the new case to dynamo
      cases: newCases
    },
    headers: {
      Authorization: jwtToken
    }
  };

  // puts record back into dynamo
  API.put("userAPI", "", userData).then(() => {
    var data = {
      user: {
        name: user.signInUserSession.idToken.payload.preferred_username,
        // Keep this in mind to manually add a case and save the API from having to put and then get to refresh the case list
        cases: userData.body.cases
      }
    };
    updateMethod(data);
  });
};
