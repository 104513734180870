import React, { Component } from "react";
import Button from "react-bootstrap/lib/Button";
import Row from "react-bootstrap/lib/Row";
import Col from "react-bootstrap/lib/Col";
import Alert from "react-bootstrap/lib/Alert";
import Modal from "react-bootstrap/lib/Modal";
import { Link, withRouter } from "react-router-dom";
import Ionicon from "react-ionicons";
import { Auth } from "aws-amplify";
import { removeFromCases } from "../apis/caseAPI";
import { connect } from "react-redux";
import "../css/case-element.css";
import { changeCaseClosed } from "../apis/userAPI";

class CaseElement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      deleteShow: false
    };

    this.handleDeleteModalShow = this.handleDeleteModalShow.bind(this);
    this.handleDeleteModalClose = this.handleDeleteModalClose.bind(this);
  }
  handleDeleteModalShow() {
    this.setState({ deleteShow: true });
  }

  handleDeleteModalClose() {
    this.setState({ deleteShow: false });
  }

  render() {
    const c = this.props.case;
    return (
      <div className="case-info-wrapper">
        <Row>
          <Col xs={12} md={7} lg={4}>
            {c.open ? (
              <Link
                to={{
                  pathname: "board/" + c.caseId,
                  state: {
                    caseId: c.caseId
                  }
                }}
                key={c.caseId}
              >
                <div className="case-info">
                  {c.caseName} <span>{c.caseNum}</span>
                </div>
              </Link>
            ) : (
              <div className="case-info">
                {c.caseName} <span>{c.caseNum}</span>
              </div>
            )}
          </Col>
          <Col xs={12} md={1} lg={4} className="date-moved">
            {getDate(c)}
          </Col>
          <Col xs={12} md={4} lg={4}>
            <div className="button-wrapper-case-element">
              <Button
                className="close-case-button"
                onClick={() => {
                  Auth.currentAuthenticatedUser().then(user => {
                    changeCaseClosed(
                      user,
                      c,
                      !c.open,
                      this.props.updateUserData,
                      this.props.cases,
                      this.props.stripe
                    );
                  });
                  this.handleDeleteModalClose();
                }}
                variant="outline-secondary"
                disabled={this.props.stripe.subscriptions.total_count === 0}
              >
                {c.open ? "Close" : "Re-Open"}
              </Button>
              <Button
                className="delete-case-button"
                onClick={() => {
                  this.handleDeleteModalShow();
                }}
                variant="outline-danger"
                disabled={this.props.stripe.subscriptions.total_count === 0}
              >
                <Ionicon
                  className="delete-case-icon"
                  icon="ios-trash-outline"
                  color="red"
                  fontSize="20px"
                />
              </Button>
            </div>
          </Col>
        </Row>
        <Modal
          show={this.state.deleteShow}
          onHide={this.handleDeleteModalClose}
        >
          <Modal.Header>
            <Modal.Title>
              Are you sure you want to delete{" "}
              <span className="case-name-delete">{c.caseName}</span>?
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Alert variant="danger">
              <h4>Warning!</h4>
              <p>
                This action can not be undone. The case will be removed from our
                records.
              </p>
            </Alert>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="danger"
              className="create-case-button"
              onClick={() => {
                Auth.currentAuthenticatedUser().then(user => {
                  removeFromCases(
                    user,
                    this.props.updateUserData,
                    this.props.cases,
                    c
                  );
                });
                this.handleDeleteModalClose();
              }}
            >
              Yes, Delete
            </Button>
            <Button
              variant="default"
              className="cancel-create-button"
              onClick={this.handleDeleteModalClose}
            >
              No, Don't Delete
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

const getDate = c => {
  let d = new Date(parseInt(c.date, 10));
  d = d.toLocaleDateString();
  return d;
};

const mapStateToProps = state => {
  return {
    cases: state.userCase.user.cases,
    user: state.userCase.user,
    stripe: state.userCase.stripe
  };
};

// Use this to update the case list of user info when querying what cases we get
const mapDispatchToProps = dispatch => {
  return {
    updateUserData: userData => {
      dispatch({
        type: "LOAD_USER",
        payload: userData
      });
    }
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(CaseElement)
);
