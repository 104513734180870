import React, { Component } from "react";
import "../css/case-list.css";
import { connect } from "react-redux";
import Button from "react-bootstrap/lib/Button";
import Form from "react-bootstrap/lib/Form";
import Row from "react-bootstrap/lib/Row";
import Col from "react-bootstrap/lib/Col";
import Modal from "react-bootstrap/lib/Modal";
import Nav from "react-bootstrap/lib/Nav";
import Breadcrumb from "react-bootstrap/lib/Breadcrumb";
import { withRouter } from "react-router-dom";
import { Auth } from "aws-amplify";
import "uuid";
import { addCase } from "../apis/userAPI";
import SearchInput, { createFilter } from "react-search-input";
import CaseElement from "./case-element";
import ReactLoading from "react-loading";
import MetaTags from "react-meta-tags";

class CaseList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
      searchTerm: "",
      show: false,
      newCaseName: "",
      loading: true,
      activeState: 1,
      caseNo: "",
      petitioner: "",
      respondant: "",
      representing: "Petitioner",
      state: "Iowa",
      county: ""
    };

    this.searchUpdated = this.searchUpdated.bind(this);
    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleCaseNameChange = this.handleCaseNameChange.bind(this);
    this.handleOpenStateChange = this.handleOpenStateChange.bind(this);
  }

  handleShow() {
    this.setState({ show: true });
  }

  handleClose() {
    this.setState({ show: false });
  }

  handleCaseNameChange(e) {
    this.setState({ newCaseName: e.target.value });
  }

  handleOpenStateChange(selected) {
    this.setState({ activeState: parseInt(selected, 10) });
  }

  componentDidUpdate() {
    if (this.state.loading && this.props.user.name !== "") {
      setTimeout(() => {
        this.setState({
          loading: false
        });
      }, 400);
    }
    if (this.state.show) {
      // not working correctly
      // this.refDiv.focus();
    }
  }

  componentDidMount() {
    this.setState({ loading: this.props.user.name === "" });

    // Internet Explorer 6-11
    let isIE = /*@cc_on!@*/ false || !!document.documentMode;
    if (isIE) {
      alert(
        "Internet Explorer is no longer supported by Microsoft and it is recommended that you use a different browser. We cannot guarantee functionality in this browser."
      );
    }
  }

  handleKeyPress = e => {
    if (e.key === "Enter") {
      e.preventDefault();
      e.stopPropagation();
      this.handleCreateCase();
    }
  };

  handleCreateCase = () => {
    Auth.currentAuthenticatedUser()
      .then(user => {
        addCase(
          user,
          this.state.newCaseName,
          this.state.caseNo,
          this.state.petitioner,
          this.state.respondant,
          this.state.representing,
          this.props.updateUserData,
          this.props.cases,
          this.state.state,
          this.state.county,
          this.props.stripe
        );
      })
      .catch(err => {
        console.log(err);
      });

    this.handleClose();
  };

  handleCaseNumberChange = e => {
    this.setState({ caseNo: e.target.value });
  };

  handleRepresentChange = e => {
    this.setState({ representing: e.target.value });
  };

  handlePetChange = e => {
    this.setState({ petitioner: e.target.value });
  };

  handleStateChange = e => {
    this.setState({ state: e.target.value });
  };

  handleCountyChange = e => {
    this.setState({ county: e.target.value });
  };

  handleResChange = e => {
    this.setState({ respondant: e.target.value });
  };

  render() {
    var filteredCases;
    if (this.props.cases !== undefined) {
      filteredCases = this.props.cases.filter(c => {
        return (
          (this.state.activeState === 1 && c.open) ||
          (this.state.activeState === 2 && !c.open)
        );
      });
      filteredCases = filteredCases.filter(
        createFilter(this.state.searchTerm, ["caseName", "caseNum"])
      );
    }
    if (this.state.loading) {
      return (
        <div className="loading-icon-div">
          <div className="loading-icon-wrapper">
            <div className="loading-icon">
              <ReactLoading type="bars" color="grey" height={100} width={100} />
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="case-dashboard-wrapper">
          <MetaTags>
            <meta
              name="viewport"
              content="width=device-width, initial-scale=1, shrink-to-fit=no"
            />
          </MetaTags>
          <Row className="app-nav-container">
            <Col xs={12} md={4}>
              <Breadcrumb id="breadcrumb">
                <Breadcrumb.Item active>{this.props.user.name}</Breadcrumb.Item>
                <Breadcrumb.Item active>Cases</Breadcrumb.Item>
              </Breadcrumb>
            </Col>
          </Row>
          <div className="case-dashboard-nav">
            <Row className="justify-content-sm-center">
              <Col xs={12} md={4}>
                <SearchInput
                  className="search-input"
                  onChange={this.searchUpdated}
                />
              </Col>
              <Col xs={12} md={4}>
                <div className="pill-wrapper">
                  <Nav
                    variant="pills"
                    className="open-close-pills"
                    activeKey={this.state.activeState}
                    onSelect={this.handleOpenStateChange}
                  >
                    <Nav.Item>
                      <Nav.Link className="open-pill" eventKey={1}>
                        Open
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link className="closed-pill" eventKey={2}>
                        Closed
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                </div>
              </Col>
              <Col xs={12} md={4}>
                <Button
                  className="add-case-button"
                  onClick={this.handleShow}
                  variant="outline-primary"
                  disabled={this.props.stripe.subscriptions.total_count === 0}
                >
                  <div className="add-case-button-text">+ Add Case</div>
                </Button>
              </Col>
            </Row>
          </div>
          <div className="dashboard-wrapper">
            <div className="case-dashboard">
              <div className="case-list">
                {createCaseComponents(filteredCases, this.state.searchTerm)}
              </div>
            </div>
          </div>
          <Modal show={this.state.show} onHide={this.handleClose}>
            <Modal.Header>
              <Modal.Title>Create Case</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form onSubmit={this.handleCreateCase}>
                <Form.Group variant="sm">
                  <Form.Label>Case Name</Form.Label>
                  <Form.Control
                    ref={ref => (this.refDiv = ref)}
                    autoFocus="true"
                    type="text"
                    placeholder="Enter name"
                    label="Case Name"
                    onChange={this.handleCaseNameChange}
                    onKeyPress={this.handleKeyPress}
                  />
                  <Form.Text>
                    {"This helps you identify your case later."}
                  </Form.Text>
                </Form.Group>
                <Form.Group variant="sm">
                  <Form.Label>State</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder=""
                    label="State"
                    value="Iowa"
                    disabled
                    //Adding more states change here
                    //onChange={this.handleStateChange}
                    onKeyPress={this.handleKeyPress}
                  />
                  <Form.Text>
                    {
                      "The State in which the divorce is being filed. Cannot be changed later. (Iowa is the only currently offered state)"
                    }
                  </Form.Text>
                </Form.Group>
                <Form.Group variant="sm">
                  <Form.Label>County</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder=""
                    label="County"
                    onChange={this.handleCountyChange}
                    onKeyPress={this.handleKeyPress}
                  />
                  <Form.Text>
                    {"The County in which the divorce is being filed."}
                  </Form.Text>
                </Form.Group>
                <Form.Group variant="sm">
                  <Form.Label>Case Number</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Case No."
                    label="Case Number"
                    onChange={this.handleCaseNumberChange}
                    onKeyPress={this.handleKeyPress}
                  />
                  <Form.Text>{"State case number."}</Form.Text>
                </Form.Group>
                <Form.Group variant="sm">
                  <Form.Label>Petitioner</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Name"
                    label="Petitioner"
                    onChange={this.handlePetChange}
                    onKeyPress={this.handleKeyPress}
                  />
                  <Form.Text>{"Name of the petitioner."}</Form.Text>
                </Form.Group>
                <Form.Group variant="sm">
                  <Form.Label>Respondant</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Name"
                    label="Respondant"
                    onChange={this.handleResChange}
                    onKeyPress={this.handleKeyPress}
                  />
                  <Form.Text>{"Name of the Respondant"}</Form.Text>
                </Form.Group>
                <Form.Group variant="sm">
                  <Form.Label>Representing:</Form.Label>
                  <Form.Control
                    as="select"
                    onChange={this.handleRepresentChange}
                    defaultValue={"Petitioner"}
                  >
                    <option>Petitioner</option>
                    <option>Respondant</option>
                  </Form.Control>
                  <Form.Text>{"Which party are you representing?"}</Form.Text>
                </Form.Group>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button
                type="submit"
                variant="outline-primary"
                className="create-case-button"
                onClick={this.handleCreateCase}
              >
                Create
              </Button>
              <Button
                variant="outline-secondary"
                className="cancel-create-button"
                onClick={this.handleClose}
              >
                Cancel
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      );
    }
  }

  searchUpdated(term) {
    this.setState({ searchTerm: term });
  }
}

const createCaseComponents = (cases, searchTerm) => {
  if (cases === undefined || cases.length === 0) {
    if (searchTerm === "") {
      return (
        <div className="no-cases">Looks like you don't have any cases yet!</div>
      );
    } else {
      return (
        <div className="no-cases-searched">
          Can't find a case with that name!
        </div>
      );
    }
  }

  const caseElements = cases.map(c => <CaseElement case={c} key={c.caseId} />);
  return caseElements;
};

const mapStateToProps = state => {
  return {
    cases: state.userCase.user.cases,
    user: state.userCase.user,
    stripe: state.userCase.stripe
  };
};

// Use this to update the case list of user info when querying what cases we get
const mapDispatchToProps = dispatch => {
  return {
    updateUserData: userData => {
      dispatch({
        type: "LOAD_USER",
        payload: userData
      });
    }
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(CaseList)
);
