import React, { Component } from "react";
import Row from "react-bootstrap/lib/Row";
import Col from "react-bootstrap/lib/Col";
import Card from "react-bootstrap/lib/Card";
import Ionicon from "react-ionicons";
import "../../css/pricing.css";

class Pricing extends Component {
  render() {
    return (
      <div className="home">
        <h4 className="page-header">
          Pricing <small>View different subscription options</small>
        </h4>
        <hr />
        <div className="pricing-header">
          <h4>SECO makes it easy with subscription based payments!</h4>
        </div>
        <p className="info-p">
          We offer the ability to pay by the month, or for a full year for your
          account. The trial period is applied to every new account.
        </p>
        <div className="pricing-types">
          <Row className="justify-content-sm-center">
            <Col md={3}>
              <Card>
                <Card.Body>
                  <Ionicon
                    color="#3b83ff"
                    icon="md-trending-up"
                    fontSize="50px"
                  />
                  <Card.Title className="price-title">Trial Period</Card.Title>
                  <Card.Text className="price">30 Days</Card.Text>
                  <Card.Text className="desc">
                    Continue with selected payment plan or cancel during the
                    trial period.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col md={3}>
              <Card>
                <Card.Body>
                  <Ionicon color="#3b83ff" icon="md-pricetag" fontSize="50px" />
                  <Card.Title className="price-title">Monthly</Card.Title>
                  <Card.Text className="price">$50/mo</Card.Text>
                  <Card.Text className="desc">
                    Pay monthly, upgrade to yearly at anytime.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col md={3}>
              <Card>
                <Card.Body>
                  <Ionicon
                    color="#3b83ff"
                    icon="md-pricetags"
                    fontSize="50px"
                  />
                  <Card.Title className="price-title">Yearly</Card.Title>
                  <Card.Text className="price">$500/yr</Card.Text>
                  <Card.Text className="desc">
                    Pay yearly, downgrade to monthly at anytime.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </div>
        <p className="info-p">
          You may cancel at any time. Your account will then be active until the
          end of your billing cycle.
        </p>
      </div>
    );
  }
}

export default Pricing;
