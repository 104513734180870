import React, { Component } from "react";
import Alert from "react-bootstrap/lib/Alert";
import Form from "react-bootstrap/lib/Form";
import Button from "react-bootstrap/lib/Button";
import Col from "react-bootstrap/lib/Col";
import Row from "react-bootstrap/lib/Row";
import Card from "react-bootstrap/lib/Card";
import { Auth, API } from "aws-amplify";
import CheckoutForm from "./card-form";
import { Elements } from "react-stripe-elements";
import Ionicon from "react-ionicons";

class SignUp extends Component {
  state = {
    username: "",
    email: "",
    password: "",
    confirmPw: "",
    confirmCode: "",
    confirmPwState: false,
    success: false,
    emailExists: false,
    subType: "",
    unchoosenSub: false
  };

  handleUsernameChange = e => {
    this.setState({ username: e.target.value });
  };

  handleEmailChange = e => {
    this.setState({ email: e.target.value });
  };

  handlePasswordChange = e => {
    this.setState({ password: e.target.value, invalidPw: false });
  };

  handleConfirmPasswordChange = e => {
    this.setState({ confirmPw: e.target.value, invalidPw: false }, function() {
      if (
        this.state.password !== "" &&
        this.state.password === this.state.confirmPw
      ) {
        this.setState({ confirmPwState: true });
      } else {
        this.setState({ confirmPwState: false });
      }
    });
  };

  handleConfirmCodeChange = e => {
    this.setState({ confirmCode: e.target.value });
  };

  handleSignUp = stripeToken => {
    this.setState({ emailExists: false });
    if (this.state.password === this.state.confirmPw) {
      if (this.state.subType === "") {
        this.setState({ unchoosenSub: true });
        return;
      }
      Auth.signUp({
        username: this.state.email,
        password: this.state.password,
        attributes: {
          preferred_username: this.state.username
        }
      })
        .then(response => {
          var userId = response.userSub;

          let body = {
            body: {
              userId: userId,
              token: stripeToken,
              subLength: this.state.subType,
              email: this.state.email,
              referral_code: this.state.referralCode
            },
            headers: {}
          };

          console.log(body);

          API.post("paymentAPI", "", body)
            .then(resp => {
              if (resp.err) {
                this.setState({
                  cardChargeErr: true,
                  cardErrMessage: resp.err.message
                });
              } else {
                this.setState({ success: true });
              }
            })
            .catch(err => console.log(err));
        })
        .catch(err => {
          if (err.code === "UsernameExistsException") {
            this.setState({ emailExists: true });
          } else if (err.code === "InvalidParameterException") {
            this.setState({ invalidPw: true });
          }
          console.log(err);
        });
    } else {
      this.setState({ confirmPwState: false });
    }
  };

  getHelpText = () => {
    if (this.state.confirmPwState) {
      return <Form.Text>Passwords match.</Form.Text>;
    } else if (!this.state.confirmPwState) {
      return <Form.Text>Passwords must match.</Form.Text>;
    } else {
      return null;
    }
  };

  redirectToLogin = () => {
    window.location = "/login";
  };

  componentDidMount() {
    this.emailRef.focus();
  }

  handleSubscriptionChange = e => {
    this.setState({ subType: e.target.id, unchoosenSub: false });
  };

  handleReferralCodeChange = e => {
    this.setState({
      referralCode: e.target.value,
      validRefCode: false,
      invalidRefCode: false
    });
  };

  handleVerifyAccessCode = () => {
    this.setState({
      refVerifyLoading: true,
      invalidRefCode: false,
      validRefCode: false,
      adminCode: false,
      amountOff: 0
    });
    API.get("referralAPI", "/" + this.state.referralCode)
      .then(resp => {
        if (resp.err) {
          this.setState({ invalidRefCode: true, refVerifyLoading: false });
        } else if (resp.coupon) {
          this.setState({
            validRefCode: true,
            adminCode: true,
            amountOff: resp.coupon.amount_off
              ? "$" + (resp.coupon.amount_off / 100).toLocaleString()
              : resp.coupon.percent_off + "%",
            refVerifyLoading: false,
            refCodeName: resp.coupon.name,
            refCodeDuration: resp.coupon.duration
          });
        } else if (resp.validUserCode) {
          this.setState({
            validRefCode: true,
            refVerifyLoading: false,
            refCodeName: "Valid Referral Code"
          });
        } else {
          this.setState({ invalidRefCode: true, refVerifyLoading: false });
        }
      })
      .catch(err => console.log(err));
  };

  getForm = () => {
    return (
      <div className="form-page">
        <Form className="form-wrapper">
          <a className="back-to-login" href="/home/main">
            <Ionicon
              className="back-to-login-icon"
              icon="ios-arrow-round-back"
            />
            Home
          </a>
          <h2 className="form-brand-header">SECO</h2>
          <hr />
          <h3>Account information</h3>
          <Form.Row>
            <Col>
              <Form.Group controlId="email" variant="sm">
                <Form.Label>Email Address</Form.Label>
                <Form.Control
                  autoFocus="true"
                  ref={ref => {
                    this.emailRef = ref;
                  }}
                  type="email"
                  placeholder="Email"
                  onChange={this.handleEmailChange}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="username" variant="sm">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="First Last"
                  onChange={this.handleUsernameChange}
                />
              </Form.Group>
              <Form.Text>
                Please be accurate, this will be applied to legal documents.
              </Form.Text>
            </Col>
          </Form.Row>
          <Form.Group controlId="password" variant="sm">
            <Form.Label>Password</Form.Label>
            <Form.Control
              ref={ref => {
                this.passwordRef = ref;
              }}
              type="password"
              placeholder="Create a password"
              onChange={this.handlePasswordChange}
            />
            <Form.Text>
              Password must be at least 8 characters, containing at least one
              uppercase, one lowercase, and one number.
            </Form.Text>
          </Form.Group>
          <Form.Group controlId="confirmPw" variant="sm">
            <Form.Label>Confirm Password</Form.Label>
            <Form.Control
              ref={ref => {
                this.confirmPassRef = ref;
              }}
              type="password"
              placeholder="Confirm your password"
              onChange={this.handleConfirmPasswordChange}
              isValid={this.state.confirmPwState}
              isInvalid={
                this.state.confirmPw !== "" && !this.state.confirmPwState
              }
            />
            <Form.Control.Feedback
              type={this.state.confirmPwState ? "valid" : "invalid"}
            >
              {this.getHelpText()}
            </Form.Control.Feedback>
          </Form.Group>
          {this.state.emailExists && (
            <Alert variant="danger">
              An account with this email already exists.
            </Alert>
          )}
          {this.state.invalidPw && (
            <Alert variant="danger">Password does not meet requirements.</Alert>
          )}

          <hr />
          <h3>Choose a subscription plan</h3>
          <p>
            A <span className="trial-message-bold">30 Day Free Trial</span> will
            be applied to your account. The plan you select will then continue
            after your trial has ended, unless you choose to cancel your
            subscription.
          </p>
          <fieldset>
            <Form.Group onChange={this.handleSubscriptionChange}>
              <Row>
                <Col>
                  <Card>
                    <Card.Header>Monthly Subscription</Card.Header>
                    <Card.Body>Pay monthly. Cancel anytime.</Card.Body>
                    <Card.Footer>
                      <Form.Check
                        type="radio"
                        inline
                        label="$50/mo"
                        name="formHorizontalRadios"
                        id="monthly"
                      />
                    </Card.Footer>
                  </Card>
                </Col>
                <Col>
                  <Card>
                    <Card.Header>Yearly Subscription</Card.Header>
                    <Card.Body>Pay yearly. Cancel anytime.</Card.Body>
                    <Card.Footer>
                      <Form.Check
                        type="radio"
                        inline
                        label="$500/yr"
                        name="formHorizontalRadios"
                        id="yearly"
                      />
                    </Card.Footer>
                  </Card>
                </Col>
              </Row>
            </Form.Group>
          </fieldset>
          <hr />
          {this.state.unchoosenSub && (
            <Alert variant="danger">You must select a subscription type.</Alert>
          )}
          <div>
            <h3>Referral Code</h3>
            <div>
              <Form.Group controlId="referralCode" variant="sm">
                <Form.Label>Code</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Code"
                  onChange={this.handleReferralCodeChange}
                />
              </Form.Group>
              {this.state.validRefCode && (
                <Alert variant="success">
                  <Alert.Heading>{this.state.refCodeName}</Alert.Heading>
                  {this.state.adminCode && (
                    <p>
                      {this.state.amountOff} will be deducted from your first (
                      {this.state.refCodeDuration}) payment(s).
                    </p>
                  )}
                </Alert>
              )}
              {this.state.invalidRefCode && (
                <Alert variant="danger">Invalid Code</Alert>
              )}
              <div className="button-wrapper">
                <div className="button-div">
                  <Button
                    variant="outline-primary"
                    onClick={() => this.handleVerifyAccessCode()}
                    className="verify-access-button"
                    disabled={this.state.refVerifyLoading}
                  >
                    {this.state.refVerifyLoading ? "...Loading" : "Verify Code"}
                  </Button>
                </div>
              </div>
            </div>
            <hr />
          </div>
          <h3>Payment Information</h3>
          <Elements>
            <CheckoutForm
              submitToken={token => this.handleSignUp(token)}
              signup
              email={this.state.email}
              name={this.state.username}
            />
          </Elements>
        </Form>
      </div>
    );
  };

  render() {
    if (this.state.cardChargeErr) {
      return (
        <div className="form-page">
          <Form className="form-wrapper">
            <h2 className="form-brand-header">SECO</h2>
            <hr />
            <h4 className="confirm-account-create">
              Account Created, Card Failure
            </h4>
            <div className="icon-wrapper">
              <Ionicon
                icon="md-checkmark-circle-outline"
                color="red"
                fontSize="60px"
              />
            </div>
            <Alert variant="danger">
              Your account was created, but your card failed for the following
              reason:
              <p className="card-err-message">{this.state.cardErrMessage}</p>
              Due to this, your subscription is not yet complete. Please
              continue to login, you will enter your new payment information
              there.
            </Alert>
            <Form.Group className="form-footer">
              <Button
                variant="outline-primary"
                className="submit-form-button"
                onClick={() => {
                  this.redirectToLogin();
                }}
              >
                Go to Login
              </Button>
            </Form.Group>
          </Form>
        </div>
      );
    } else if (this.state.success) {
      return (
        <div className="form-page">
          <Form className="form-wrapper">
            <h2 className="form-brand-header">SECO</h2>
            <hr />
            <h4 className="confirm-account-create">Account Created!</h4>
            <div className="icon-wrapper">
              <Ionicon
                icon="md-checkmark-circle-outline"
                color="green"
                fontSize="60px"
              />
            </div>
            {this.state.cardChargeErr && (
              <Alert variant="danger">
                Your account was created, but your card failed for the following
                reason. {this.state.cardErrMessage} Due to this, your
                subscription is not yet complete. Please login, and continue to
                the Account -> Subscription page to select your subscription.
              </Alert>
            )}
            <Form.Group className="form-footer">
              <Button
                variant="outline-primary"
                className="submit-form-button"
                onClick={() => {
                  this.redirectToLogin();
                }}
              >
                Go to Login
              </Button>
            </Form.Group>
          </Form>
        </div>
      );
    }
    return this.getForm();
  }
}

export default SignUp;
