import React, { Component } from "react";
import { CardElement, injectStripe } from "react-stripe-elements";
import Alert from "react-bootstrap/lib/Alert";
import Form from "react-bootstrap/lib/Form";
import Button from "react-bootstrap/lib/Button";
import "../../css/card-form.css";

class CheckoutForm extends Component {
  state = {
    incorrectCard: false
  };

  constructor(props) {
    super(props);
    this.submit = this.submit.bind(this);
  }

  async submit() {
    let { token } = await this.props.stripe.createToken({
      name: this.props.name,
      email: this.props.email
    });

    this._element.clear();
    if (token === undefined) {
      this.setState({ incorrectCard: true });
    } else {
      this.props.submitToken(token);
    }
  }

  render() {
    var style = {
      base: {
        color: "#32325d",
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: "#aab7c4"
        }
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a"
      }
    };

    return (
      <div className="checkout">
        {this.props.signup || this.props.cardFailed ? null : (
          <div>
            This will change your default payment for the next billing cycle.
          </div>
        )}
        <CardElement
          className="card-element"
          style={style}
          onReady={element => (this._element = element)}
        />
        <a href="https://stripe.com">
          <img
            className="stripe-badge"
            src="/img/stripe2.png"
            alt="stripe badge"
          />
        </a>
        {this.state.incorrectCard ? (
          <Alert variant="danger">Card info incorrect.</Alert>
        ) : null}
        {this.props.signup ? (
          <div>
            You will not be charged until your trial period has ended in 30
            days.
            <hr />
            <div className="signup-confirm">
              By clicking create account, you have read and agree to the
              <a href="/home/terms"> Terms and Conditions.</a>
            </div>
            <Form.Group className="form-footer">
              <a className="form-footer-link" href="/login">
                Already have an account? Sign In
              </a>
              <Button
                className="submit-form-button"
                variant="outline-primary"
                onClick={() => {
                  this.submit();
                }}
                disabled={this.state.loadingCreateAccount}
              >
                {this.state.loadingCreateAccount
                  ? "...Loading"
                  : "Create Account"}
              </Button>
            </Form.Group>
          </div>
        ) : (
          <div className="bottom-div">
            {this.props.cardFailed && "You will be charged immediately."}
            <hr />
            <div className="button-wrapper">
              <div className="button-div">
                <Button
                  variant="outline-primary"
                  className="submit-form-button"
                  onClick={() => {
                    this.submit();
                  }}
                  disabled={
                    this.props.loading ||
                    (!this.props.subChosen && this.props.cardFailed)
                  }
                >
                  {this.props.loading
                    ? "...Loading"
                    : this.props.cardFailed
                    ? "Confirm Payment"
                    : "Change Card"}
                </Button>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default injectStripe(CheckoutForm);
