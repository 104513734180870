import Lh from "../react-trello/helpers/LaneHelper";
import { saveBoardData } from "../apis/caseAPI";

export const initBoard = {
  finances: {
    petitioner: {
      source: "",
      gross: 0,
      net: 0,
      deductions: {
        fedTax: 0,
        stateTax: 0,
        fica: 0,
        union: 0,
        occFees: 0,
        support: 0,
        medSupport: 0,
        childCare: 0,
        disability: 0
      }
    },
    respondant: {
      source: "",
      gross: 0,
      net: 0,
      deductions: {
        fedTax: 0,
        stateTax: 0,
        fica: 0,
        union: 0,
        occFees: 0,
        support: 0,
        medSupport: 0,
        childCare: 0,
        disability: 0
      }
    },
    residential: {
      sameDwelling: "No",
      physicalCareChildren: "NA",
      childrenResideInFamily: "NA"
    },
    expenses: {
      housePmtRent: 0,
      houseInsurance: 0,
      telephone: 0,
      utilities: 0,
      clothing: 0,
      grooming: 0,
      incidentals: 0,
      laundry: 0,
      food: 0,
      carInsurance: 0,
      carPayments: 0,
      healthInsurance: 0,
      tv: 0
    },
    installments: []
  },
  lanes: [
    {
      id: "petitioner",
      title: "Petitioner",
      cards: []
    },
    {
      id: "nuetral",
      title: "Undecided",
      cards: []
    },
    {
      id: "respondant",
      title: "Respondant",
      cards: []
    }
  ]
};

export const boardReducer = (state = initBoard, action) => {
  const { payload, type } = action;
  var result;
  switch (type) {
    case "LOAD_BOARD":
      result = Lh.initialiseLanes(state, payload);
      if (payload.caseId !== "") {
        result = {
          ...result,
          case: payload.case,
          caseId: payload.caseId,
          finances:
            payload.finances === undefined ? state.finances : payload.finances
        };
      }
      return result;
    case "ADD_CARD":
      // Controls the index where to add new card
      if (payload.index === undefined) payload.index = 0;
      result = Lh.appendCardToLane(state, payload);
      saveBoardData(result, result.caseId);
      return result;
    case "REMOVE_CARD":
      result = Lh.removeCardFromLane(state, payload);
      saveBoardData(result, result.caseId);
      return result;
    case "MOVE_CARD":
      result = Lh.moveCardAcrossLanes(state, payload);
      saveBoardData(result, result.caseId);
      return result;
    case "UPDATE_CARDS":
      result = Lh.updateCardsForLane(state, payload);
      saveBoardData(result, result.caseId);
      return result;
    case "UPDATE_LANES":
      result = Lh.updateLanes(state, payload);
      return result;
    case "PAGINATE_LANE":
      result = Lh.paginateLane(state, payload);
      return result;
    case "MOVE_LANE":
      result = Lh.moveLane(state, payload);
      return result;
    case "EMPTY_BOARD":
      result = Lh.initialiseLanes(initBoard, payload);
      return result;
    case "EDIT_FINANCES":
      result = state;
      result.finances = payload;
      saveBoardData(result, result.caseId);
      return result;
    default:
      result = state;
      return result;
  }
};
