import React, { Component } from "react";
import { connect } from "react-redux";
import Button from "react-bootstrap/lib/Button";
import Form from "react-bootstrap/lib/Form";
import Alert from "react-bootstrap/lib/Alert";
import Nav from "react-bootstrap/lib/Nav";
import Navbar from "react-bootstrap/lib/Navbar";
import ButtonGroup from "react-bootstrap/lib/ButtonGroup";
import NavDropdown from "react-bootstrap/lib/NavDropdown";
import { withRouter } from "react-router-dom";
import { handleSignOut } from "../apis/userAPI";
import { Auth } from "aws-amplify";
import "../css/custom-page-nav.css";
import { LinkContainer } from "react-router-bootstrap";

class CustomPageNav extends Component {
  state = {
    username: "",
    userLoggedIn: false
  };

  componentDidMount() {
    if (this.props.user.name === "") {
      Auth.currentAuthenticatedUser()
        .then(() => {
          this.setState({ userLoggedIn: true });
        })
        .catch(() => {
          this.setState({ userLoggedIn: false });
        });
    } else {
      this.setState({ userLoggedIn: true });
    }
  }

  render() {
    return (
      <div>
        <Navbar
          className="custom-nav justify-content-between"
          sticky="top"
          expand="lg"
        >
          <Navbar.Brand href="/home/main">
            <span className="custom-nav-header">SECO</span>
            <div className="tagline">Divorce Management</div>
          </Navbar.Brand>
          <Navbar.Collapse id="basic-navbar-nav">
            {correctNav(this.props)}

            {this.state.userLoggedIn ? (
              loggedInUser(this.props)
            ) : (
              <Form inline className="auth-buttons-nav">
                <ButtonGroup>
                  <Button
                    className="auth-button"
                    variant="outline-primary"
                    href="/login"
                  >
                    Log In
                  </Button>
                  <Button className="auth-button" href="/signup">
                    Sign Up
                  </Button>
                </ButtonGroup>
              </Form>
            )}
          </Navbar.Collapse>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
        </Navbar>
        {this.state.userLoggedIn &&
          this.props.stripe &&
          this.props.stripe.subscriptions.total_count === 0 && (
            <Alert className="nav-alert" variant="danger">
              Your subscription has expired. Functionality will be limited to
              read-only until you re-subscribe in{" "}
              <a href={"/" + this.props.user.name + "/account"}>
                account settings.
              </a>
            </Alert>
          )}
      </div>
    );
  }
}

const correctNav = props => {
  if (props.internal) {
    return (
      <Nav>
        <Nav.Link href={"/" + props.user.name + "/cases"}>
          <span className={props.cases ? "tab-active" : ""}>Cases</span>
        </Nav.Link>
        {/* <Nav.Link href={"/home/tutorials"}>Tutorials</Nav.Link> */}
        <Nav.Link href={"https://docs.secodivorce.com"} target="_blank">
          <span>Docs</span>
        </Nav.Link>
        <Nav.Link href={"/home/contact-us"}>
          <span className={props.contactUs ? "tab-active" : ""}>
            Contact Us
          </span>
        </Nav.Link>
      </Nav>
    );
  }
  return (
    <Nav>
      <Nav.Link
        onClick={() =>
          window.open("https://demo.secodivorce.com/DemoUser/cases", "_blank")
        }
      >
        Demo
      </Nav.Link>
      <Nav.Link href={"/home/pricing"}>
        <span className={props.pricing ? "tab-active" : ""}>Pricing</span>
      </Nav.Link>
      {/* <Nav.Link href={"/home/tutorials"}>Tutorials</Nav.Link> */}
      <Nav.Link href={"https://docs.secodivorce.com"} target="_blank">
        <span>Docs</span>
      </Nav.Link>
      <Nav.Link href={"/home/contact-us"}>
        <span className={props.contactUs ? "tab-active" : ""}>Contact Us</span>
      </Nav.Link>
    </Nav>
  );
};

const loggedInUser = props => {
  var username = props.user.name;
  return (
    <div className="auth-buttons-nav">
      <NavDropdown title={"Hi " + username + "!"} id="user-dropdown">
        <LinkContainer to={"/" + username + "/cases"}>
          <NavDropdown.Item eventKey="3.0">Cases</NavDropdown.Item>
        </LinkContainer>
        <LinkContainer to={"/" + username + "/account"}>
          <NavDropdown.Item eventKey="3.1">Account</NavDropdown.Item>
        </LinkContainer>
        <NavDropdown.Divider />
        <NavDropdown.Item eventKey="3.4" onClick={() => handleSignOut()}>
          Sign Out
        </NavDropdown.Item>
      </NavDropdown>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    user: state.userCase.user,
    stripe: state.userCase.stripe
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateUserData: userData => {
      dispatch({
        type: "LOAD_USER",
        payload: userData
      });
    }
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(CustomPageNav)
);
