import React, { Component } from "react";
import "../../css/terms.css";

class TermsConditions extends Component {
  state = {};
  render() {
    return (
      <div className="home">
        <h4 className="page-header">Terms and Conditions</h4>
        <hr />
        <div className="pricing-types">
          <ul>
            <li>
              <a href="/terms/TermsofUse.pdf" target="_blank">
                Terms of Use
              </a>
            </li>
            <li>
              <a href="/terms/PrivacyPolicy.pdf" target="_blank">
                Privacy Policy
              </a>
            </li>
            <li>
              <a href="/terms/CookiesPolicy.pdf" target="_blank">
                Cookies Policy
              </a>
            </li>
            <li>
              <a href="/terms/Disclaimer.pdf" target="_blank">
                Disclaimer
              </a>
            </li>
          </ul>
        </div>
        <hr />
        <p className="info-p">
          We reserve the right to change these terms at any time, in a
          reasonable manner.
        </p>
      </div>
    );
  }
}

export default TermsConditions;
