import React, { Component } from "react";
import Row from "react-bootstrap/lib/Row";
import Col from "react-bootstrap/lib/Col";
import Tab from "react-bootstrap/lib/Tab";
import Nav from "react-bootstrap/lib/Nav";
import Form from "react-bootstrap/lib/Form";
import Button from "react-bootstrap/lib/Button";
import Alert from "react-bootstrap/lib/Alert";
import Card from "react-bootstrap/lib/Card";
import Modal from "react-bootstrap/lib/Modal";
import "../css/account-page.css";
import { Auth, API } from "aws-amplify";
import CheckoutForm from "./authForms/card-form";
import { Elements } from "react-stripe-elements";
import { connect } from "react-redux";
import MetaTags from "react-meta-tags";

class AccountPage extends Component {
  state = {
    tab: "settings",
    confirmPwState: null,
    oldPassword: "",
    newPassword: "",
    confirmNewPassword: "",
    changePassSuccess: false,
    subType: "",
    show: false,
    confirmPassword: "",
    stripeUser: {},
    validPW: false,
    currentAction: "",
    referralCode: ""
  };

  componentDidMount() {
    this.getStripeUser();
    Auth.currentAuthenticatedUser().then(user => {
      this.setState({ referralCode: user.attributes["custom:referral_code"] });
    });
  }

  getStripeUser = () => {
    Auth.currentAuthenticatedUser()
      .then(user => {
        const options = {
          headers: {
            Authorization: user.signInUserSession.idToken.jwtToken
          },
          body: {
            stripeId: user.attributes["custom:stripeId"],
            userId: user.username
          }
        };

        API.put("paymentAPI", "/request", options)
          .then(r => this.setState({ stripeUser: r.customer }))
          .catch(err => console.log(err));
      })
      .catch(err => console.log(err));
  };

  handleTabChange = e => {
    this.setState({ tab: e });
  };

  handleSubscriptionChange = e => {
    if (this.state.subType !== "") {
      this.setState({ subType: "" });
    } else if (e.target.value === "on") {
      this.setState({ subType: e.target.id });
    }
  };

  getCorrectPage = () => {
    switch (this.state.tab) {
      case "account-settings":
        return <div>{this.passwordComp()}</div>;
      case "referrals":
        return this.referralsComp();
      case "payment":
        return this.paymentComp();
      case "subscription":
        return this.subscriptionComp();
      default:
        return <div>{this.passwordComp()}</div>;
    }
  };

  handleConfirmPasswordChallengeChange = e => {
    this.setState({ confirmPassword: e.target.value, failedAuth: false });
  };

  render() {
    return (
      <div className="account-page">
        <MetaTags>
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1, shrink-to-fit=no"
          />
        </MetaTags>
        <Row className="justify-content-lg-center">
          <Col lg={4} md={8} sm={12}>
            <div className="nav-section-account">
              <Tab.Container
                id="table-nav"
                defaultActiveKey="account-settings"
                onSelect={this.handleTabChange}
              >
                <Nav variant="pills" className="flex-column">
                  <Nav.Item>
                    <Nav.Link eventKey="account-settings">
                      Account Settings
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="subscription">Subscription</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="payment">Payment</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="referrals">Referrals</Nav.Link>
                  </Nav.Item>
                </Nav>
              </Tab.Container>
            </div>
          </Col>
          <Col lg={5} sm={12}>
            {this.getCorrectPage()}
          </Col>
        </Row>
        <Modal show={this.state.show} onHide={this.handleClose}>
          <Modal.Header>
            <Modal.Title>Confirm Password</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group controlId="formHorizontalPassword">
                <Form.Label>Password</Form.Label>
                <Form.Control
                  ref={ref => {
                    this.passwordRef = ref;
                  }}
                  type="password"
                  placeholder="Password"
                  onChange={this.handleConfirmPasswordChallengeChange}
                />
              </Form.Group>

              {this.state.failedAuth ? (
                <Alert variant="danger">Invalid password</Alert>
              ) : null}
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button
              type="submit"
              variant="outline-primary"
              className="create-case-button"
              onClick={() => this.confirmPromptPassword()}
            >
              Confirm
            </Button>
            <Button
              variant="outline-secondary"
              className="cancel-create-button"
              onClick={() => this.handleClose()}
            >
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }

  getHelpText = () => {
    if (this.state.confirmPwState) {
      return <Form.Text>Passwords match.</Form.Text>;
    } else if (!this.state.confirmPwState) {
      return <Form.Text>Passwords must match.</Form.Text>;
    } else {
      return null;
    }
  };

  handleOldPasswordChange = e => {
    this.setState({
      oldPassword: e.target.value,
      requirementsNotMet: false,
      changePassSuccess: false,
      wrongPw: false
    });
  };

  handleNewPasswordChange = e => {
    this.setState({
      newPassword: e.target.value,
      wrongPw: false,
      requirementsNotMet: false,
      changePassSuccess: false
    });
  };

  handleConfirmPasswordChange = e => {
    this.setState(
      {
        confirmNewPassword: e.target.value,
        wrongPw: false,
        requirementsNotMet: false,
        changePassSuccess: false
      },
      function() {
        if (
          this.state.newPassword !== "" &&
          this.state.newPassword === this.state.confirmNewPassword
        ) {
          this.setState({ confirmPwState: true });
        } else {
          this.setState({ confirmPwState: false });
        }
      }
    );
  };

  handlePasswordChangeClick = () => {
    this.setState({ loadingChangePass: true, limitExceeded: false });
    if (
      this.state.oldPassword !== "" &&
      this.state.newPassword !== "" &&
      this.state.newPassword === this.state.confirmNewPassword
    ) {
      var pw = this.state.newPassword;
      if (pw.toUpperCase() !== pw && pw.toLowerCase() !== pw && /\d/.test(pw)) {
        Auth.currentAuthenticatedUser()
          .then(user => {
            return Auth.changePassword(
              user,
              this.state.oldPassword,
              this.state.newPassword
            );
          })
          .then(() => {
            this.setState({
              oldPassword: "",
              newPassword: "",
              confirmNewPassword: "",
              changePassSuccess: true,
              loadingChangePass: false
            });
            this.clearPw();
          })
          .catch(err => {
            console.log(err);
            if (err.code === "LimitExceededException") {
              this.setState({ limitExceeded: true, loadingChangePass: false });
            } else if (err.code === "NotAuthorizedException") {
              this.setState({ wrongPw: true, loadingChangePass: false });
            }

            this.setState({ loadingChangePass: false });
            this.clearPw();
          });
      } else {
        console.log("here");
        this.setState({
          requirementsNotMet: true,
          loadingChangePass: false
        });
        this.clearPw();
      }
    } else {
      this.setState({
        requirementsNotMet: true,
        loadingChangePass: false
      });
      this.clearPw();
    }
  };

  clearPw = () => {
    this.oldPwRef.value = "";
    this.newPwRef.value = "";
    this.confirmPwRef.value = "";

    this.setState({
      oldPassword: "",
      newPassword: "",
      confirmNewPassword: "",
      confirmPwState: false
    });
  };

  passwordComp = () => {
    return (
      <div className="password-page">
        <div className="section">
          <h4>Password</h4>
          <p>Change your current password.</p>
          <hr />
          <Form>
            <Form.Group controlId="oldPw" variant="sm">
              <Form.Label>Current Password</Form.Label>
              <Form.Control
                ref={ref => {
                  this.oldPwRef = ref;
                }}
                type="password"
                placeholder="Current Password"
                onChange={this.handleOldPasswordChange}
              />
            </Form.Group>
            <hr />
            <Form.Group controlId="newPw" variant="sm">
              <Form.Label>New Password</Form.Label>
              <Form.Control
                ref={ref => {
                  this.newPwRef = ref;
                }}
                type="password"
                placeholder="New Password"
                onChange={this.handleNewPasswordChange}
              />
              <Form.Text>
                Password must be at least 8 characters, containing at least one
                uppercase, one lowercase, and one number.
              </Form.Text>
            </Form.Group>
            <Form.Group controlId="confirmPw" variant="sm">
              <Form.Label>Confirm New Password</Form.Label>
              <Form.Control
                ref={ref => {
                  this.confirmPwRef = ref;
                }}
                type="password"
                placeholder="Confirm Password"
                onChange={this.handleConfirmPasswordChange}
                isValid={this.state.confirmPwState}
              />
              <Form.Control.Feedback>
                {this.getHelpText()}
              </Form.Control.Feedback>
            </Form.Group>
          </Form>
          {this.state.requirementsNotMet ? (
            <Alert variant="danger">
              Requirements not met. Please check your password strength and try
              again.
            </Alert>
          ) : null}
          {this.state.changePassSuccess ? (
            <Alert variant="success">Password changed successfully.</Alert>
          ) : null}
          {this.state.wrongPw ? (
            <Alert variant="danger">Incorrect password.</Alert>
          ) : null}
          {this.state.limitExceeded ? (
            <Alert variant="warning">
              Too many attempts to change password, please try again later.
            </Alert>
          ) : null}
          <div className="button-wrapper">
            <Button
              className="button-acct"
              variant="outline-primary"
              onClick={() => this.handlePasswordChangeClick()}
              disabled={this.state.loadingChangePass}
            >
              {this.state.loadingChangePass ? "...Loading" : "Change Password"}
            </Button>
          </div>
        </div>
      </div>
    );
  };

  promptPassword = () => {
    if (!this.state.validPW) {
      this.setState({ show: true });
    }
  };

  handleClose = () => {
    this.setState({
      show: false,
      loadingChangeSub: false,
      loadingChangePass: false,
      loadingChangeCard: false,
      loadingCancelSub: false
    });
  };

  referralsComp = () => {
    return (
      <div className="referral-page">
        <div className="section">
          <h4>Referrals</h4>
          <hr />
          <div>Referral code: {this.state.referralCode}</div>
          <hr />
          <div>
            Current account balance: ${this.props.stripe.account_balance / 100}{" "}
            (negative value indicates you have earned credit for referrals).
          </div>
          <hr />
          <p>
            Share your referral code with others to get a credit towards your
            account. For every person you refer to SECO, you will earn a $50
            credit that will be applied to your next bill. The limit is $500
            credit per year.
          </p>
        </div>
      </div>
    );
  };

  handleCardChange = token => {
    this.setState({
      currentAction: "changeCard",
      loadingChangeCard: true,
      token: token,
      cardChangeErr: false,
      cardChangeSuccess: false
    });

    if (!this.state.validPW) {
      this.promptPassword();
    } else {
      Auth.currentAuthenticatedUser().then(user => {
        var userId = user.username;

        let body = {
          body: {
            userId: userId,
            token: token,
            stripeId: user.attributes["custom:stripeId"]
          },
          headers: {
            Authorization: user.signInUserSession.idToken.jwtToken
          }
        };

        API.patch("paymentAPI", "", body)
          .then(resp => {
            if (resp.err) {
              this.setState({
                loadingChangeCard: false,
                cardChangeErr: true,
                cardErr: resp.err.message
              });
            } else {
              this.getStripeUser();
              this.handleClose();
              this.setState({
                token: "",
                loadingChangeCard: false,
                cardChangeSuccess: true
              });
            }
          })
          .catch(err => {
            console.log(err);
          });
      });
    }
  };

  confirmPromptPassword = () => {
    Auth.signIn(this.props.user.email, this.state.confirmPassword)
      .then(() => {
        this.setState({ validPW: true, show: false });
        switch (this.state.currentAction) {
          case "changeSub":
            this.setState({ loadingChangeSub: false });
            this.changeSubscription();
            break;
          case "cancelSub":
            this.setState({ loadingCancelSub: false });
            this.cancelSubscription();
            break;
          case "changeCard":
            this.setState({ loadingChangeCard: false });
            this.handleCardChange(this.state.token);
            break;
          default:
            break;
        }
      })
      .catch(err => {
        console.log(err);
        this.setState({ failedAuth: true, validPW: false });
      });
  };

  paymentComp = () => {
    const card = this.state.stripeUser.sources.data[0];
    return (
      <div className="payment-page">
        <div className="section">
          <div>
            <h4>Current Payment Source</h4>
            <hr />
            <Row>
              <Col>{card.brand}</Col>
              <Col>{"**** " + card.last4}</Col>
              <Col>{"Exp. " + card.exp_month + "/" + card.exp_year}</Col>
            </Row>
          </div>
          <hr />
          <h4>Change Payment Method</h4>
          <Elements>
            <CheckoutForm
              submitToken={token => this.handleCardChange(token)}
              loading={this.state.loadingChangeCard}
            />
          </Elements>
          {this.state.cardChangeErr && (
            <Alert variant="danger">{this.state.cardErr}</Alert>
          )}
          {this.state.cardChangeSuccess && (
            <Alert variant="success">Card changed successfully!</Alert>
          )}
        </div>
      </div>
    );
  };

  subscriptionComp = () => {
    let stripeInvalid = this.state.stripeUser.subscriptions.total_count === 0;
    const sub = this.state.stripeUser.subscriptions.data[0];

    return (
      <div className="sub-page">
        <div className="section">
          <div>
            <h4>
              Current Subscription{" "}
              <span className="plan-name">
                {stripeInvalid ? "Expired" : sub.plan.nickname}
              </span>
            </h4>
            <div>
              Current billing cycle:{" "}
              {stripeInvalid
                ? "Expired"
                : new Date(
                    sub.current_period_start * 1000
                  ).toLocaleDateString() +
                  " - " +
                  new Date(sub.current_period_end * 1000).toLocaleDateString()}
            </div>
            <div />
          </div>
          <hr />
          <h4>{stripeInvalid ? "Subscribe" : "Change Subscription"}</h4>
          {stripeInvalid ? (
            <Alert variant="secondary">
              <Alert.Heading>Attention!</Alert.Heading>
              You may subscribe to the plan of your choice. You will be charged
              immediately.
            </Alert>
          ) : (
            <Alert variant="secondary">
              <Alert.Heading>Attention!</Alert.Heading>
              You may change your billing cycle at any time.
              <hr />
              <ul>
                <li>
                  <p>
                    Downgrading (yearly to monthly plan), you will be prorated
                    the amount unused on your current subscription to be applied
                    towards future plans, this does not include refunds.
                  </p>
                </li>
                <li>
                  <p>
                    Upgrading (monthly to yearly plan), you will be prorated the
                    unused amount this month, and charged immediately for the
                    remainder of the total.
                  </p>
                </li>
              </ul>
            </Alert>
          )}
          <fieldset>
            <Form.Group
              onChange={this.handleSubscriptionChange}
              value={this.state.subType}
            >
              <Row>
                <Col>
                  <Card>
                    <Card.Header>Monthly Subscription</Card.Header>
                    <Card.Body>Pay monthly. Cancel anytime.</Card.Body>
                    <Card.Footer>
                      <Form.Check
                        custom
                        type="checkbox"
                        inline
                        label={
                          !stripeInvalid &&
                          sub.plan.id === "plan_EAoTsxh03W4az3"
                            ? "Current - $50 / month"
                            : "$50 / month"
                        }
                        name="formHorizontalRadios"
                        id="monthly"
                        disabled={
                          !stripeInvalid &&
                          sub.plan.id === "plan_EAoTsxh03W4az3" &&
                          !sub.cancel_at_period_end
                        }
                      />
                    </Card.Footer>
                  </Card>
                </Col>
                <Col>
                  <Card>
                    <Card.Header>Yearly Subscription</Card.Header>
                    <Card.Body>Pay yearly. Cancel anytime.</Card.Body>
                    <Card.Footer>
                      <Form.Check
                        custom
                        type="checkbox"
                        inline
                        label={
                          !stripeInvalid &&
                          sub.plan.id !== "plan_EAoTsxh03W4az3"
                            ? "Current - $500 / year"
                            : "$500 / year"
                        }
                        name="formHorizontalRadios"
                        id="yearly"
                        disabled={
                          !stripeInvalid &&
                          sub.plan.id !== "plan_EAoTsxh03W4az3" &&
                          !sub.cancel_at_period_end
                        }
                      />
                    </Card.Footer>
                  </Card>
                </Col>
              </Row>
            </Form.Group>
          </fieldset>
          <div className="button-wrapper">
            <Button
              className="button-acct"
              onClick={() => this.changeSubscription()}
              variant="outline-success"
              disabled={
                this.state.subType === "" || this.state.loadingChangeSub
              }
            >
              {this.state.subType === ""
                ? "Select Option"
                : this.state.loadingChangeSub
                ? "...Loading"
                : stripeInvalid || sub.cancel_at_period_end
                ? "Subscribe"
                : "Change Subscription"}
            </Button>
          </div>
          {this.state.subChangeErr && (
            <Alert variant="danger">
              Changing subscription failed.
              <p className="card-err-message">
                {this.state.subChangeErrMessage}
              </p>
              You may need to update your payment information.
            </Alert>
          )}
          {this.state.changeSubSuccess && (
            <Alert variant="success">Subscription changed successfully!</Alert>
          )}
          <hr />
          <div>
            <h4>Cancel Subscription</h4>
            {stripeInvalid ? (
              <Alert variant="warning">
                Your subscription has expired. Please re-subscribe to regain
                full capability.
              </Alert>
            ) : sub.cancel_at_period_end ? (
              <Alert variant="danger">
                You have chosen to cancel your subscription to Seco.
                Subscription will end at the next billing cycle. You are able to
                resubscribe above at any time.
              </Alert>
            ) : (
              <div>
                <div>
                  Your subscription will continue until the end of your current
                  pay period. You will not be charged again. You may resubscribe
                  at any time.
                </div>
                <div className="button-wrapper">
                  <Button
                    className="button-acct"
                    onClick={() => this.cancelSubscription()}
                    variant="outline-danger"
                    disabled={sub.cancel_at_period_end}
                  >
                    {this.state.loadingCancelSub
                      ? "...Loading"
                      : "Cancel Subscription"}
                  </Button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  changeSubscription = () => {
    this.setState({
      currentAction: "changeSub",
      loadingChangeSub: true,
      subChangeErr: false,
      changeSubSuccess: false
    });
    if (!this.state.validPW) {
      this.promptPassword();
    } else if (this.state.subType !== "") {
      Auth.currentAuthenticatedUser()
        .then(user => {
          const jwtToken = user.signInUserSession.idToken.jwtToken;

          const json = {
            body: {
              stripeId: user.attributes["custom:stripeId"],
              subLength: this.state.subType
            },
            headers: {
              Authorization: jwtToken
            }
          };

          API.put("paymentAPI", "", json)
            .then(res => {
              console.log(res);
              if (res.err) {
                this.setState({
                  subChangeErr: true,
                  subChangeErrMessage: res.err.message,
                  loadingChangeSub: false
                });
              } else {
                this.getStripeUser();
                this.setState({
                  loadingChangeSub: false,
                  subType: "",
                  changeSubSuccess: true
                });
              }
            })
            .catch(err => {
              console.log(err);
              this.setState({ loadingChangeSub: false });
            });
        })
        .catch(err => {
          this.setState({ loadingChangeSub: false });
        });
    }
  };

  cancelSubscription = () => {
    this.setState({ currentAction: "cancelSub", loadingCancelSub: true });

    if (!this.state.validPW) {
      this.promptPassword();
    } else {
      Auth.currentAuthenticatedUser().then(user => {
        const jwtToken = user.signInUserSession.idToken.jwtToken;

        const json = {
          body: {
            stripeId: user.attributes["custom:stripeId"]
          },
          headers: {
            Authorization: jwtToken
          }
        };
        API.del("paymentAPI", "", json)
          .then(() => {
            this.getStripeUser();
            this.setState({ loadingCancelSub: false });
          })
          .catch(err => {
            console.log(err);
            this.setState({ loadingCancelSub: false });
          });
      });
    }
  };
}

const mapStateToProps = state => {
  return {
    user: state.userCase.user,
    stripe: state.userCase.stripe
  };
};

const mapDispatchToProps = () => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AccountPage);
