import React, { Component } from "react";
import Row from "react-bootstrap/lib/Row";
import Col from "react-bootstrap/lib/Col";
import Navbar from "react-bootstrap/lib/Navbar";
import "../css/custom-footer.css";

class CustomFooter extends Component {
  render() {
    return (
      <Navbar fixed="bottom" className="footer-wrapper">
        <div className="footer">
          <Row>
            <Col md={4}>SECO Divorce Management Software</Col>
            <Col md={4}>
              <a href="/home/terms" target="_blank">
                Terms and Conditions
              </a>
            </Col>
            <Col md={4}>Copyright 2018. Random Otter Technologies.</Col>
          </Row>
        </div>
      </Navbar>
    );
  }
}

export default CustomFooter;
