import React, { Component } from "react";
import CustomPageNav from "../custom-page-nav";
import CustomFooter from "../custom-footer";
import Main from "./main.js";
import Pricing from "./pricing.js";
import "../../css/home.css";
import Tutorials from "./tutorials";
import ContactUs from "./contact-us";
import TermsConditions from "./terms-and-conditions";

class LandingPage extends Component {
  renderCorrectElement = page => {
    switch (page) {
      case "main":
        return <Main />;
      case "pricing":
        return <Pricing />;
      case "tutorials":
        return <Tutorials />;
      case "contact-us":
        return <ContactUs />;
      case "terms":
        return <TermsConditions />;
      default:
        return <Main />;
    }
  };

  render() {
    let page = this.props.match.params.infoPage;
    return (
      <div>
        <CustomPageNav
          pricing={page === "pricing"}
          contactUs={page === "contact-us"}
        />
        {this.renderCorrectElement(page)}
        <CustomFooter />
      </div>
    );
  }
}

export default LandingPage;
