import React, { Component } from "react";
import Alert from "react-bootstrap/lib/Alert";
import Form from "react-bootstrap/lib/Form";
import Button from "react-bootstrap/lib/Button";
import { Auth } from "aws-amplify";
import { fetchUserData } from "../../apis/userAPI";
import { connect } from "react-redux";
import Ionicon from "react-ionicons";
import "../../css/auth-forms.css";

class Login extends Component {
  state = {
    email: "",
    password: "",
    verify: false,
    confirmCode: "",
    forwardLink: "",
    failedAuth: false
  };

  handleEmailChange = e => {
    this.setState({ email: e.target.value });
  };

  handlePasswordChange = e => {
    this.setState({ password: e.target.value });
  };

  handleConfirmCodeChange = e => {
    this.setState({ confirmCode: e.target.value });
  };

  componentDidMount() {
    if (this.state.verify) {
      this.confirmRef.focus();
    } else {
      this.emailRef.focus();
    }
  }

  handleSignIn() {
    this.setState({ failedAuth: false, loadingSignIn: true });
    Auth.signIn(this.state.email, this.state.password)
      .then(user => {
        this.setState({
          forwardLink:
            "/" +
            user.signInUserSession.idToken.payload.preferred_username +
            "/cases",
          loadingSignIn: false
        });
        Auth.currentAuthenticatedUser().then(user => {
          this.setState({ loadingSignIn: true });
          fetchUserData(user, this.props.updateUserData);
        });
        if (user.signInUserSession.idToken.payload.email_verified) {
          this.props.history.push(this.state.forwardLink);
        } else {
          this.setState({ verify: true });
          this.confirmRef.value = "";
          Auth.verifyCurrentUserAttribute("email").then(() => {
            console.log("email sent");
          });
        }
      })
      .catch(err => {
        console.log(err);
        this.passwordRef.value = "";
        this.setState({ failedAuth: true, loadingSignIn: false });
      });
  }

  handleConfirmCode() {
    this.setState({ loadingConfirmCode: true });
    Auth.verifyCurrentUserAttributeSubmit("email", this.state.confirmCode)
      .then(() => {
        console.log("email verified");
        this.setState({ loadingConfirmCode: false });
        window.location = this.state.forwardLink;
      })
      .catch(() => {
        this.setState({ loadingConfirmCode: false });
        alert("wrong code");
      });
  }

  handleKeyPress = e => {
    if (e.key === "Enter") {
      this.handleSignIn();
    }
  };

  handleConfirmKeyPress = e => {
    if (e.key === "Enter") {
      this.handleConfirmCode();
    }
  };

  render() {
    if (this.state.verify) {
      return (
        <div className="form-page">
          <Form className="form-wrapper">
            <h2 className="form-brand-header">SECO</h2>
            <hr />
            <h3>Confirm Email Address</h3>
            <p>
              In order to recover your account if you lose your password, we
              must verify your email address. We have sent an email to you.
              Please enter your 7-digit confirmation code below.
            </p>
            <Form.Group controlId="confirm-code">
              <Form.Label>Confirmation Code</Form.Label>
              <Form.Control
                autoFocus="true"
                ref={ref => {
                  this.confirmRef = ref;
                }}
                type="text"
                placeholder="Code"
                onChange={this.handleConfirmCodeChange}
                onKeyPress={this.handleConfirmKeyPress}
              />
            </Form.Group>

            <Form.Group className="form-footer">
              <Button
                variant="outline-primary"
                className="submit-form-button"
                onClick={() => {
                  this.handleConfirmCode();
                }}
                disabled={this.state.loadingConfirmCode}
              >
                {this.state.loadingConfirmCode ? "...Loading" : "Confirm Code"}
              </Button>
              <Button
                variant="outline-primary"
                onClick={() => {
                  Auth.verifyCurrentUserAttribute("email").then(() => {
                    console.log("email sent");
                    alert("New Code Sent!");
                  });
                }}
              >
                Send new code
              </Button>
            </Form.Group>
          </Form>
        </div>
      );
    }

    return (
      <div className="form-page">
        <Form className="form-wrapper">
          <a className="back-to-login" href="/home/main">
            <Ionicon
              className="back-to-login-icon"
              icon="ios-arrow-round-back"
            />
            Home
          </a>
          <h2 className="form-brand-header">SECO</h2>
          <hr />
          <Form.Group controlId="formBasicText">
            <Form.Label>Email</Form.Label>
            <Form.Control
              ref={ref => {
                this.emailRef = ref;
              }}
              autoFocus="true"
              type="text"
              placeholder="Email"
              onChange={this.handleEmailChange}
              onKeyPress={this.handleKeyPress}
            />
          </Form.Group>

          <Form.Group controlId="formHorizontalPassword">
            <Form.Label>Password</Form.Label>
            <Form.Control
              ref={ref => {
                this.passwordRef = ref;
              }}
              type="password"
              placeholder="Password"
              onChange={this.handlePasswordChange}
              onKeyPress={this.handleKeyPress}
            />
          </Form.Group>

          {this.state.failedAuth ? (
            <Alert variant="danger">Invalid Username or password</Alert>
          ) : null}

          <Form.Group className="form-footer">
            <a className="form-footer-link create-account" href="/signup">
              Create Account
            </a>

            <a className="form-footer-link" href="/forgotPassword">
              Forgot Password?
            </a>
            <Button
              className="submit-form-button"
              variant="outline-primary"
              onClick={() => {
                this.handleSignIn();
              }}
              disabled={this.state.loadingSignIn}
            >
              {this.state.loadingSignIn ? "...Loading" : "Sign In"}
            </Button>
          </Form.Group>
        </Form>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.userCase.user
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateUserData: userData => {
      dispatch({
        type: "LOAD_USER",
        payload: userData
      });
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Login);
