import "babel-polyfill";
import React from "react";
import "bootstrap/dist/css/bootstrap.css";
import "./css/index.css";
import App from "./App";
import { createStore, combineReducers } from "redux";
// ^ add applyMiddleware
//import logger from "redux-logger";
import { Provider } from "react-redux";
import { render } from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import Amplify from "aws-amplify";
import awsmobile from "./aws-exports";
import { boardReducer } from "./reducers/board-reducer";
import { userCaseReducer } from "./reducers/user-reducer";

Amplify.configure(awsmobile);

const store = createStore(
  combineReducers({ userCase: userCaseReducer, board: boardReducer }),
  {} //,
  //applyMiddleware(logger)
);

store.subscribe(() => {});

render(
  <Provider store={store}>
    <Router>
      <App />
    </Router>
  </Provider>,
  document.getElementById("root")
);
