import React, { Component } from "react";
import Row from "react-bootstrap/lib/Row";
import Col from "react-bootstrap/lib/Col";
import Card from "react-bootstrap/lib/Card";
import "../../css/contact-us.css";

class ContactUs extends Component {
  render() {
    return (
      <div className="home">
        <h4 className="page-header">
          Contact Us <small>We'd love to hear from you</small>
        </h4>
        <hr />

        <div className="pricing-header">
          If we aren't available we'll respond promptly. Thank you!
        </div>
        <div className="pricing-types">
          <Card className="contact-card">
            <Row className="card-info">
              <Col md={12} lg={4}>
                <div className="image">
                  <img src="/img/hunter.jpg" alt="hunter" />
                </div>
                <h4 className="name">Hunter Hall</h4>
              </Col>
              <Col md={12} lg={8}>
                <div className="email">hunterhall@secodivorce.com</div>
                <div className="phone">(515)-720-2835</div>
              </Col>
            </Row>
          </Card>
          <Card className="contact-card">
            <Row className="card-info">
              <Col md={12} lg={4}>
                <div className="image-wrapper">
                  <img src="/img/jake.jpg" alt="jake" />
                </div>
                <h4 className="name">Jacob Veatch</h4>
              </Col>
              <Col md={12} lg={8}>
                <div className="email">jacobveatch@secodivorce.com</div>
                <div className="phone">(641)-521-4175</div>
              </Col>
            </Row>
          </Card>
        </div>
      </div>
    );
  }
}

export default ContactUs;
