import Amplify from "aws-amplify";

Amplify.configure({
  API: {
    endpoints: [
      {
        name: "userAPI",
        endpoint:
          "https://6f17xk8t2e.execute-api.us-east-2.amazonaws.com/dev/user",
        service: "apigateway",
        region: "us-east-2"
      },
      {
        name: "caseAPI",
        endpoint:
          "https://6f17xk8t2e.execute-api.us-east-2.amazonaws.com/dev/case",
        service: "apigateway",
        region: "us-east-2"
      },
      {
        name: "formAPI",
        endpoint:
          "https://6f17xk8t2e.execute-api.us-east-2.amazonaws.com/dev/forms",
        service: "apigateway",
        region: "us-east-2"
      },
      {
        name: "paymentAPI",
        endpoint:
          "https://6f17xk8t2e.execute-api.us-east-2.amazonaws.com/dev/payments",
        service: "apigateway",
        region: "us-east-2"
      },
      {
        name: "referralAPI",
        endpoint:
          "https://6f17xk8t2e.execute-api.us-east-2.amazonaws.com/dev/referral",
        service: "apigateway",
        region: "us-east-2"
      }
    ]
  },
  Auth: {
    userPoolId: "us-east-2_53nMdbJdF",
    userPoolWebClientId: "6uclmqanr8bpt2rbkqaoe8338u",
    region: "us-east-2",
    mandatorySignIn: true
  }
});

const awsmobile = {
  aws_project_region: "us-east-2",
  aws_content_delivery_bucket: "divorceapp-20180906135126--hostingbucket",
  aws_content_delivery_bucket_region: "us-east-2",
  aws_content_delivery_url:
    "http://divorceapp-20180906135126--hostingbucket.s3-website.us-east-2.amazonaws.com"
};

export default awsmobile;
