import { API, Auth } from "aws-amplify";

export const fetchCaseData = (userId, idToken, thisCaseId, updateMethod) => {
  let jwtToken = idToken;
  const options = {
    headers: {
      Authorization: jwtToken
    }
  };

  API.get("caseAPI", "/" + userId + thisCaseId, options)
    .then(response => {
      var data = {
        caseId: thisCaseId,
        case: response.case,
        lanes: response.lanes,
        finances: response.finances
      };
      updateMethod(data);
    })
    .catch(err => {
      console.log(err);
    });

  return thisCaseId;
};

//SAVE THIS BITCH BACK HERE
export const saveBoardData = (boardData, caseId) => {
  Auth.currentAuthenticatedUser()
    .then(user => {
      let jwtToken = user.signInUserSession.idToken.jwtToken;
      let userId = user.signInUserSession.idToken.payload.sub;

      const options = {
        body: {
          case: boardData.case,
          lanes: boardData.lanes,
          finances: boardData.finances
        },
        headers: {
          Authorization: jwtToken
        }
      };
      API.put("caseAPI", "/" + userId + caseId, options);
    })
    .catch(() => {});
};

export const removeFromCases = (user, updateMethod, cases, caseToRemove) => {
  cases.splice(cases.indexOf(caseToRemove), 1);
  deleteCase(user, updateMethod, cases, caseToRemove);
};

const deleteCase = (user, updateMethod, newCases, caseToRemove) => {
  let jwtToken = user.signInUserSession.idToken.jwtToken;
  let userId = user.signInUserSession.idToken.payload.sub;

  const userData = {
    body: {
      userId: user.signInUserSession.idToken.payload.sub,
      // this will send the new case to dynamo
      cases: newCases
    },
    headers: {
      Authorization: jwtToken
    }
  };
  API.put("userAPI", "", userData)
    .then(() => {
      var data = {
        user: {
          name: user.signInUserSession.idToken.payload.preferred_username,
          // Keep this in mind to manually add a case and save the API from having to put and then get to refresh the case list
          cases: newCases
        }
      };
      updateMethod(data);
    })
    .catch(err => {
      console.log(err);
    });
  const headers = {
    headers: {
      Authorization: jwtToken
    }
  };
  API.del("caseAPI", "/" + userId + caseToRemove.caseId, headers).catch(err => {
    console.log(err);
  });
};

export const saveClientFormData = (caseId, accessCode, givenData) => {
  var data = {
    body: givenData
  };

  var resp = 0;

  API.put("formAPI", "/validate/" + caseId + "/" + accessCode, data)
    .then(() => {})
    .catch(err => {
      console.log(err);
      resp = 1;
    });

  return resp;
};
