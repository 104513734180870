import React, { Component } from "react";
import Alert from "react-bootstrap/lib/Alert";
import Form from "react-bootstrap/lib/Form";
import Button from "react-bootstrap/lib/Button";
import { Auth } from "aws-amplify";
import Ionicon from "react-ionicons";
import "../../css/auth-forms.css";

class ForgotPass extends Component {
  state = {
    email: "",
    code: "",
    password: "",
    confirmPassword: "",
    sentCode: false,
    confirmPwState: null,
    codeMismatch: false,
    tooManyAttempts: false
  };

  handleEmailChange = e => {
    this.setState({ email: e.target.value });
  };

  handleConfirmCodeChange = e => {
    this.setState({ code: e.target.value });
  };

  handlePasswordChange = e => {
    this.setState({ password: e.target.value });
  };

  handleConfirmPasswordChange = e => {
    this.setState({ confirmPassword: e.target.value }, function() {
      if (
        this.state.confirmPassword !== "" &&
        this.state.password === this.state.confirmPassword
      ) {
        this.setState({ confirmPwState: true });
      } else if (this.state.confirmPassword === "") {
        this.setState({ confirmPwState: null });
      } else {
        this.setState({ confirmPwState: false });
      }
    });
  };

  handleChangePassRecovery = () => {
    Auth.forgotPassword(this.state.email)
      .then(() => {
        this.setState({
          sentCode: true
        });
      })
      .catch(err => {
        //todo find error code too many times
        if (err.code === "toomanytimes") {
          this.setState({ tooManyAttempts: true });
        }
      });
  };

  handleChangePassSubmit = () => {
    if (this.state.password === this.state.confirmPassword) {
      Auth.forgotPasswordSubmit(
        this.state.email,
        this.state.code,
        this.state.password
      )
        .then(() => {
          window.location = "/login";
        })
        .catch(err => {
          if (err.code === "CodeMismatchException") {
            this.setState({ codeMismatch: true });
          }
        });
    } else {
      this.setState({ confirmPwState: "error" });
    }
  };

  getHelpText = () => {
    if (this.state.confirmPwState) {
      return <Form.Text>Passwords match.</Form.Text>;
    } else {
      return <Form.Text>Passwords must match.</Form.Text>;
    }
  };

  render() {
    if (this.state.sentCode) {
      return (
        <div className="form-page">
          <Form className="form-wrapper">
            <a className="back-to-login" href="/login">
              <Ionicon
                className="back-to-login-icon"
                icon="ios-arrow-round-back"
              />
              Login
            </a>
            <h2 className="form-brand-header">SECO</h2>
            <hr />
            <Form.Group controlId="recover-pass-confirm-code" variant="sm">
              <Form.Label>Confirmation Code</Form.Label>
              <Form.Control
                type="text"
                placeholder="Code"
                onChange={this.handleConfirmCodeChange}
              />
            </Form.Group>

            <Form.Group controlId="new-password" variant="sm">
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="Password"
                onChange={this.handlePasswordChange}
              />
              <Form.Text>
                Password must be at least 8 characters, containing at least one
                uppercase, one lowercase, and one number.
              </Form.Text>
            </Form.Group>

            <Form.Group controlId="confirm-new-password" variant="sm">
              <Form.Label>Confirm Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="Password"
                onChange={this.handleConfirmPasswordChange}
                isValid={this.state.confirmPwState}
              />
              <Form.Control.Feedback />
              {this.getHelpText()}
            </Form.Group>

            {this.state.codeMismatch ? (
              <Alert bsStyle="danger">Incorrect code</Alert>
            ) : null}

            <Form.Group className="form-footer">
              <Button
                className="submit-form-button"
                onClick={() => {
                  this.handleChangePassSubmit();
                }}
              >
                Confirm Password
              </Button>
              <Button
                onClick={() => {
                  this.handleChangePassRecovery();
                }}
              >
                Send new code
              </Button>
            </Form.Group>
          </Form>
        </div>
      );
    }
    return (
      <div className="form-page">
        <Form className="form-wrapper">
          <a className="back-to-login" href="/login">
            <Ionicon
              className="back-to-login-icon"
              icon="ios-arrow-round-back"
            />
            Login
          </a>
          <h2 className="form-brand-header">SECO</h2>
          <hr />
          <Form.Group controlId="recover-pass-email" variant="sm">
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="email"
              placeholder="Email"
              onChange={this.handleEmailChange}
            />
          </Form.Group>

          {this.state.tooManyAttempts ? (
            <Alert bsStyle="danger">
              Too many reset attempts. Try again later.
            </Alert>
          ) : null}
          <Form.Group className="form-footer">
            <div className="button-wrapper">
              <div className="button-div">
                <Button
                  variant="outline-primary"
                  className="submit-form-button"
                  onClick={() => {
                    this.handleChangePassRecovery();
                  }}
                >
                  Send Recovery Email
                </Button>
              </div>
            </div>
          </Form.Group>
        </Form>
      </div>
    );
  }
}

export default ForgotPass;
