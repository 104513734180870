import React, { Component } from "react";
import "./css/App.css";
import { withRouter } from "react-router-dom";
import Routes from "./components/authForms/private-router";
import { Auth } from "aws-amplify";
import { fetchUserData, handleSignOut } from "./apis/userAPI";
import { connect } from "react-redux";
import { StripeProvider } from "react-stripe-elements";

class App extends Component {
  checkAuth = () => {
    // Try to check out Auth.currentCredentials?
    Auth.currentAuthenticatedUser()
      .then(user => {
        var date = new Date();
        var seconds = Math.round(date.getTime() / 1000);

        if (
          user.signInUserSession === null ||
          seconds > user.signInUserSession.idToken.payload.exp
        ) {
          handleSignOut();
        } else if (
          this.props.user.name === "" ||
          this.props.user.stripe === {}
        ) {
          fetchUserData(user, this.props.updateUserData);
        }
      })
      .catch(() => {});
  };

  componentDidMount() {
    this.checkAuth();
  }

  componentDidUpdate() {
    this.checkAuth();
  }

  render() {
    return (
      <div className="App">
        <StripeProvider apiKey="pk_live_B8xhmUV61cvFbKWeqkwqVZG6">
          <Routes />
        </StripeProvider>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.userCase.user
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateUserData: userData => {
      dispatch({
        type: "LOAD_USER",
        payload: userData
      });
    }
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(App)
);
