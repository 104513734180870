import React, { Component } from "react";
import Button from "react-bootstrap/lib/Button";
import Row from "react-bootstrap/lib/Row";
import Col from "react-bootstrap/lib/Col";
import Card from "react-bootstrap/lib/Card";
import Alert from "react-bootstrap/lib/Alert";
import Ionicon from "react-ionicons";
import "../../css/main.css";
import "../../css/home.css";

class Main extends Component {
  render() {
    return (
      <div className="home">
        <div className="main-summary">
          <Alert className="state-alert" variant="primary" dismissible>
            IMPORTANT! Currently serving the state of Iowa. We cannot guarantee
            service for other states.
          </Alert>
          <div className="jumbo">
            <div className="jumbo-content">
              <Row className="jumbo-row justify-content-md-center">
                <Col lg={6} md={8} sm={12} xs={12}>
                  <div style={{ margin: "auto" }}>
                    <h2 className="main-header">
                      SECO - Divorce Management Software
                    </h2>
                    <div className="main-desc">
                      Web application for attorneys to handle divorce cases.
                      <p style={{ paddingTop: 45, paddingBottom: 25 }}>
                        SECO makes it easy to gather, manage, and report client
                        financial information.
                      </p>
                      <div style={{ textAlign: "left" }}>
                        <h3 style={{ marginBottom: 5 }}>FEATURES</h3>
                        <ul style={{ listStyleType: "disc" }}>
                          <li>Secure online client forms</li>
                          <li>Asset tracking</li>
                          <li>
                            Auto-Generated forms: financial affadavit, asset
                            allocation offer
                          </li>
                        </ul>
                      </div>
                      <div style={{ marginTop: "45px", fontStyle: "italic" }}>
                        <p>30-Day risk-free trial</p>
                      </div>
                    </div>
                    <div className="main-create-account-button">
                      <Button className="jumbo-button" href="/signup">
                        Sign Up
                      </Button>
                      <Button
                        className="jumbo-button"
                        href="https://demo.secodivorce.com"
                      >
                        Live Demo
                      </Button>
                    </div>
                  </div>
                </Col>
                <Col lg={3} sm={12}>
                  <div
                    style={{
                      margin: "auto",
                      textAlign: "center",
                      marginBottom: "50px"
                    }}
                  >
                    <img
                      className="screensnip"
                      src="/img/screensnips.png"
                      alt="screenshot"
                    />
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </div>
        <div style={{ maxWidth: "1500px", margin: "auto" }}>
          <div className="main-workflow">
            <Row className="main-row" style={{ textAlign: "left" }}>
              <Col md={4} xs={12} className="hidden-xs hidden-s">
                <Card>
                  <Card.Body>
                    <Ionicon
                      color="#3b83ff"
                      icon="ios-archive-outline"
                      fontSize="50px"
                    />
                    <Card.Title>Collect client info</Card.Title>
                    <Card.Text>
                      Send clients an online financial affadavit form. This
                      online form will walk them through the process, limiting
                      questions that you might receive. Once they are done, they
                      submit the data back to you.
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
              <Col md={4} xs={12}>
                <Card>
                  <Card.Body>
                    <Ionicon
                      color="#3b83ff"
                      icon="ios-clipboard-outline"
                      fontSize="50px"
                    />
                    <Card.Title>Manage and Track Assets</Card.Title>
                    <Card.Text>
                      Our asset board allows you to drag and drop assets while
                      you decide the division of assets. The board calculates
                      totals automatically, always verifying that you are
                      creating fair offers.
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
              <Col md={4} xs={12}>
                <Card>
                  <Card.Body>
                    <Ionicon
                      color="#3b83ff"
                      icon="ios-document-outline"
                      fontSize="50px"
                    />
                    <Card.Title>Generate Documents</Card.Title>
                    <Card.Text>
                      We make it easy to generate documents. With the click of a
                      button, we can generate a financial affadavit for your
                      client, or an asset allocation offer to send directly to
                      your legal counterpart.
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </div>
          <div className="main-devices">
            <Row
              className="main-row justify-content-lg-left"
              style={{ textAlign: "left" }}
            >
              <Col md={4} xs={12} className="main-devices-col">
                <Card>
                  <Card.Body>
                    <div>
                      <Ionicon
                        color="#3b83ff"
                        icon="ios-desktop-outline"
                        fontSize="50px"
                      />
                      <Ionicon
                        color="#3b83ff"
                        icon="ios-laptop"
                        fontSize="50px"
                      />
                      <Ionicon
                        color="#3b83ff"
                        icon="ios-tablet-portrait"
                        fontSize="50px"
                      />
                    </div>
                    <Card.Title>Available On Your Devices</Card.Title>
                    <Card.Text>
                      SECO gives you the ability to access your dashboard from
                      any device. We are hard at work to bring you the best
                      experience for any device size.
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
              <Col md={4} xs={12} className="main-devices-col">
                <Card>
                  <Card.Body>
                    <div>
                      <Ionicon
                        color="#3b83ff"
                        icon="ios-cloud-done-outline"
                        fontSize="50px"
                      />
                    </div>
                    <Card.Title>Access Your Cases Anywhere</Card.Title>
                    <Card.Text>
                      All you need is an internet connection. SECO is fast, and
                      we hope to make you more efficient in your work. SECO
                      brings the power of the cloud to automate your divorce
                      cases.
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </div>
          <hr />
          <div className="pricing" style={{ marginLeft: "50px" }}>
            <h3 style={{ marginBottom: "5px" }}>Pricing</h3>
            <p>SECO offers subscription-based payments to make paying easy.</p>
            <Button
              className="jumbo-button"
              href="https://secodivorce.com/home/pricing"
              style={{ marginTop: "60px" }}
            >
              View Pricing
            </Button>
            <div style={{ fontStyle: "italic", marginTop: "5px" }}>
              All accounts begin with a 30-day free trail.
            </div>
          </div>
          <hr />
          <div className="browsers" style={{ textAlign: "left" }}>
            <h3
              style={{
                marginLeft: "50px",
                marginBottom: "5px",
                marginTop: "45px"
              }}
            >
              Supported Browsers
            </h3>
            <div className="p-wrapper">
              <div
                className="browser-disclaimer"
                style={{ marginLeft: "27px" }}
              >
                For security and tool purposes, please ensure your browser is up
                to date.
              </div>
            </div>
            <Row
              className="main-row justify-content-md-left"
              style={{ marginLeft: "10px" }}
            >
              <Col md={4} sm={4} xs={12}>
                <Card>
                  <Card.Body>
                    <div className="icon-wrapper">
                      <img
                        className="browser-icon"
                        src="/img/chrome.png"
                        alt="Chrome"
                      />
                    </div>
                    <Card.Title>
                      Google Chrome
                      <p>
                        <span>Recommended</span>
                      </p>
                    </Card.Title>
                  </Card.Body>
                </Card>
              </Col>
              <Col md={4} sm={4} xs={12}>
                <Card>
                  <Card.Body>
                    <div className="icon-wrapper">
                      <img
                        className="browser-icon"
                        src="/img/firefox.png"
                        alt="Firefox"
                      />
                    </div>
                    <Card.Title>
                      Mozilla Firefox
                      <p>
                        <span>Recommended</span>
                      </p>
                    </Card.Title>
                  </Card.Body>
                </Card>
              </Col>
              <Col md={4} sm={4} xs={12}>
                <Card>
                  <Card.Body>
                    <div className="icon-wrapper">
                      <img
                        className="browser-icon"
                        src="/img/edge.png"
                        alt="Edge"
                      />
                    </div>
                    <Card.Title>
                      Microsoft edge
                      <p>
                        <span>Acceptable</span>
                      </p>
                    </Card.Title>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </div>
        </div>

        {/* <hr />
        <div className="main-reviews">
          <Carousel interval={5000}>
            <Carousel.Item>
              <div className="main-review">"A possible review</div>
            </Carousel.Item>
            <Carousel.Item>
              <div className="main-review">"Another review</div>
            </Carousel.Item>
          </Carousel>
        </div> */}
      </div>
    );
  }
}

export default Main;
