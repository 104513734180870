export const initialUserData = {
  user: {
    email: "",
    name: "",
    // Needs to be inserted by cognito **********
    userId: "",
    cases: []
  }
};

//Pass in data we get from cognito to query init user data to get userId
export const userCaseReducer = (state = initialUserData, action) => {
  //TODO set up reducer case to init user and shit
  const { payload, type } = action;
  switch (type) {
    case "LOAD_USER":
      if (
        payload !== undefined &&
        payload.user !== undefined &&
        payload.user.name !== undefined &&
        payload.user.name !== ""
      ) {
        state = {
          ...state,
          user: payload.user
        };
      }
      if (payload.stripe) {
        state = {
          ...state,
          stripe: payload.stripe
        };
      }
      break;
    default:
      break;
  }
  return state;
};
