import React from "react";
import {
  withRouter,
  Switch,
  Route,
  Redirect,
  BrowserRouter as Router
} from "react-router-dom";
import { Auth } from "aws-amplify";

import Login from "./login";
import CaseList from "../case-list";
//import Kanban from "../case-dashboard/kanban";
import CustomPageNav from "../custom-page-nav";
import LandingPage from "../landingPage/landing-page";
import SignUp from "./signup";
import ForgotPass from "./forgot-pass";
import AccountPage from "../account-page.js";
//import ClientForm from "../client-form/client-form.js";
import asyncComponent from "../asyncComponent";
import CardFailed from "./card-failed.js";
import CustomFooter from "../custom-footer";

const ClientForm = asyncComponent(() =>
  import("../client-form/client-form.js")
);
//const CaseList = asyncComponent(() => import("../case-list"));
//const LandingPage = asyncComponent(() => import("../landingPage/landing-page"));
// const AccountPage = asyncComponent(() => import("../account-page.js"));
const Kanban = asyncComponent(() => import("../case-dashboard/kanban"));
// //const Login = asyncComponent(() => import("./login"));
// const SignUp = asyncComponent(() => import("./signup"));
// const CardFailed = asyncComponent(() => import("./card-failed"));
// const ForgotPass = asyncComponent(() => import("./forgot-pass"));
//const CustomFooter = asyncComponent(() => import("../custom-footer"));

class PrivateRoute extends React.Component {
  state = {
    loaded: false,
    isAuthenticated: false
  };
  componentDidMount() {
    this.authenticate();
    this.unlisten = this.props.history.listen(() => {
      Auth.currentAuthenticatedUser()
        .then()
        .catch(() => {
          if (this.state.isAuthenticated)
            this.setState({ isAuthenticated: false });
        });
    });
  }
  componentWillUnmount() {
    this.unlisten();
  }
  authenticate() {
    Auth.currentAuthenticatedUser()
      .then(user => {
        if (user.attributes["custom:stripeId"]) {
          this.setState({ loaded: true, isAuthenticated: true });
        } else {
          this.setState({ loaded: true, isAuthenticated: true });
          this.props.history.push("/card-failed");
        }
      })
      .catch(() => this.props.history.push("/login"));
  }
  render() {
    const { component: Component, ...rest } = this.props;
    const { loaded, isAuthenticated } = this.state;
    if (!loaded) return null;
    return (
      <Route
        {...rest}
        render={() => {
          return isAuthenticated ? (
            this.props.component
          ) : (
            <Redirect
              to={{
                pathname: "/login"
              }}
            />
          );
        }}
      />
    );
  }
}

PrivateRoute = withRouter(PrivateRoute);

const Routes = () => (
  <Router>
    <Switch>
      <Route
        exact
        path="/"
        render={() => <Redirect to={{ pathname: "/home/main" }} />}
      />
      <Route exact path="/home/:infoPage" component={LandingPage} />
      <Route exact path="/login" component={Login} />
      <Route exact path="/forgotPassword" component={ForgotPass} />
      <Route exact path="/signup" component={SignUp} />
      <PrivateRoute
        exact
        path="/card-failed"
        component={
          <div className="main">
            <CardFailed />
          </div>
        }
      />
      <Route
        exact
        path="/forms/client-finance-form/:caseId"
        component={ClientForm}
      />
      <PrivateRoute
        exact
        path="/:userId/account"
        component={
          <div className="main">
            <CustomPageNav internal={true} />
            <AccountPage />
            <CustomFooter />
          </div>
        }
      />
      <PrivateRoute
        exact
        path="/:userId/cases"
        component={
          <div className="main">
            <CustomPageNav cases internal={true} />
            <CaseList />
            <CustomFooter />
          </div>
        }
      />
      <PrivateRoute
        exact
        path="/:userId/board/:caseId"
        component={
          <div className="main">
            <CustomPageNav internal={true} />
            <Kanban />
            <CustomFooter />
          </div>
        }
      />
    </Switch>
  </Router>
);

export default Routes;
