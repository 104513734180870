import React, { Component } from "react";
import CheckoutForm from "./card-form";
import { Elements } from "react-stripe-elements";
import Form from "react-bootstrap/lib/Form";
import Row from "react-bootstrap/lib/Row";
import Col from "react-bootstrap/lib/Col";
import Card from "react-bootstrap/lib/Card";
import Alert from "react-bootstrap/lib/Alert";
import Ionicon from "react-ionicons";
import { Auth, API } from "aws-amplify";
import { connect } from "react-redux";

class CardFailed extends Component {
  state = {
    subType: ""
  };

  componentDidMount() {
    Auth.currentAuthenticatedUser().then(user => {
      if (user.attributes["custom:stripeId"]) {
        window.location.href = "https://secodivorce.com/user/cases";
      }
      this.setState({
        userId: user.username,
        email: user.attributes.email,
        name: user.attributes.preferred_username
      });
    });
  }

  handleSignUp = stripeToken => {
    this.setState({ loading: true, cardChargeErr: false });
    var userId = this.state.userId;

    let body = {
      body: {
        userId: userId,
        token: stripeToken,
        subLength: this.state.subType,
        email: this.state.email
      },
      headers: {}
    };

    API.post("paymentAPI", "", body)
      .then(resp => {
        var body = JSON.parse(resp.body);
        if (body.err) {
          this.setState({
            cardChargeErr: true,
            cardErrMessage: body.err.message,
            loading: false
          });
        } else {
          window.location.href = "https://secodivorce.com/user/cases";
        }
      })
      .catch(err => {
        this.setState({ loading: false });
        console.log(err);
      });
  };

  handleSubscriptionChange = e => {
    this.setState({ subType: e.target.id, unchoosenSub: false });
  };

  render() {
    if (this.state.success) {
      this.props.history.push("/user/cases");
    }
    return (
      <div className="form-page">
        <Form className="form-wrapper">
          <h2 className="form-brand-header">SECO</h2>
          <hr />
          <h4 className="confirm-account-create">Card Failure</h4>
          <div className="icon-wrapper">
            <Ionicon
              icon="md-checkmark-circle-outline"
              color="red"
              fontSize="60px"
            />
          </div>
          <Alert variant="secondary">
            <Alert.Heading>Attention!</Alert.Heading>
            Your card has not been charged yet. Succesfully choosing a
            subscription plan and confirming your payment will charge you, and
            your subscription will be complete.
          </Alert>
          <hr />
          <h3>Choose a subscription plan</h3>
          <fieldset>
            <Form.Group onChange={this.handleSubscriptionChange}>
              <Row>
                <Col>
                  <Card>
                    <Card.Header>Monthly Subscription</Card.Header>
                    <Card.Body>Pay monthly. Cancel anytime.</Card.Body>
                    <Card.Footer>
                      <Form.Check
                        type="radio"
                        inline
                        label="$50/mo"
                        name="formHorizontalRadios"
                        id="monthly"
                      />
                    </Card.Footer>
                  </Card>
                </Col>
                <Col>
                  <Card>
                    <Card.Header>Yearly Subscription</Card.Header>
                    <Card.Body>Pay yearly. Cancel anytime.</Card.Body>
                    <Card.Footer>
                      <Form.Check
                        type="radio"
                        inline
                        label="$500/yr"
                        name="formHorizontalRadios"
                        id="yearly"
                      />
                    </Card.Footer>
                  </Card>
                </Col>
              </Row>
            </Form.Group>
          </fieldset>
          <hr />
          <h3>Payment Information</h3>
          <Elements>
            <CheckoutForm
              submitToken={token => this.handleSignUp(token)}
              cardFailed
              email={this.state.email}
              name={this.state.name}
              subChosen={this.state.subType !== ""}
              loading={this.state.loading}
            />
          </Elements>
          {this.state.cardChargeErr && (
            <Alert variant="danger">{this.state.cardErrMessage}</Alert>
          )}
        </Form>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.userCase.user
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateUserData: userData => {
      dispatch({
        type: "LOAD_USER",
        payload: userData
      });
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CardFailed);
